import Axios from "axios";
import config from "../config";

const axios = Axios.create({
  baseURL: `${config.API_URL}`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8;",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  // withCredentials: true,
});

export default axios;
