import { apiSlice } from "../apiSlice";

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllWidget: builder.query({
      query: () => `/admin/all-widget`,
    }),
    getDailyRevenueWithParams: builder.query({
      query: (queries) =>
        `/daily-revenue-graph?start_date=${queries.startDate}&end_date=${queries.endDate}`,
    }),
    getDailyEarningsWithParams: builder.query({
      query: (queries) =>
        `/daily-sales-graph?start_date=${queries.startDate}&end_date=${queries.endDate}`,
    }),
    getAllstates: builder.query({
      query: () => `/get-all-stats`,
    }),
    getsalesrepsAllstates: builder.query({
      query: () => `/all-sales-reps-stats`,
    }),
  }),
});

export const {
  useGetAllWidgetQuery,
  useGetDailyRevenueWithParamsQuery,
  useGetDailyEarningsWithParamsQuery,
  useGetAllstatesQuery,
  useGetsalesrepsAllstatesQuery
} = reportApiSlice;
