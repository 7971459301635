import { apiSlice } from "../apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllsettings: builder.query({
      query: () => `/get-all-settings`,
    }),
    createSettings: builder.mutation({
      query: (body) => ({
        url: `/add-settings`,
        method: "POST",
        body,
      }),
    }),
    updateSettings: builder.mutation({
      query: (body) => ({
        url: `/update-settings/${body.id}`,
        method: "PUT",
        body,
      }),
    }),
    updateSalesPerformanceNumber: builder.mutation({
      query: (body) => ({
        url: `/admin/sales-reps-edit-performance-number/${body.id}`,
        method: "PATCH",
        body,
      }),
    }),

    // updateSalesCommission: builder.mutation({
    //   query: (body) => ({
    //     url: `/admin/sales-reps-edit-comission/${body.id}`,
    //     method: "PATCH",
    //     body,
    //   }),
    // }),

    updateSalesCommission: builder.mutation({
      query: (body) => ({
        url: `/admin/sales-reps-edit-comission/${body.id}`,
        method: "PATCH",
        body,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: scr } = await queryFulfilled;
          dispatch(
            settingsApiSlice.util.updateQueryData(
              "getAllsettings",
              undefined,
              (draft) => {
                return draft.map((report: any) => {
                  if (report.id === scr.id) {
                    return scr;
                  }
                  return report;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGetAllsettingsQuery,
  useCreateSettingsMutation,
  useUpdateSettingsMutation,
  useUpdateSalesPerformanceNumberMutation,
  useUpdateSalesCommissionMutation,
} = settingsApiSlice;
