import React, { useEffect } from "react";
import Dropzone from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FormInput, PageBreadcrumb } from "../../components";
import FAQFormSkeleton from "../../components/UI/LoadingSkeleton/FAQFormSkeleton";
import toastMessage from "../../components/UI/LoadingSkeleton/toastMessage";
import config from "../../config";
import {
  useGetSingleFaqQuery,
  useUpdateFaqMutation,
} from "../../redux/rtk/features/faq/faqApiSlice";
import axios from "../../utils/axios";

const UpdateFAQ = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading: faqLoading } = useGetSingleFaqQuery(id);
  const [updateFaq] = useUpdateFaqMutation();

  const [updatedQuestion, setUpdatedQuestion] = React.useState("");
  const [updatedAnswer, setUpdatedAnswer] = React.useState("");
  const [file, setFile] = React.useState<string | "">("");

  useEffect(() => {
    if (!faqLoading) {
      setUpdatedQuestion(data.question);
      setUpdatedAnswer(data.answer);
      setFile(data.files);
    }
  }, [faqLoading]);

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log("Updated the faq");
    const updatedData: Record<string, unknown> = {
      id: id,
    };
    if (updatedQuestion !== "") updatedData.question = updatedQuestion;
    if (updatedAnswer !== "") updatedData.answer = updatedAnswer;
    if (file !== data?.files) updatedData.files = file;

    const result = await Swal.fire({
      title: "Do you want to update this Question?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes!",
      cancelButtonText: "No,cancel!",
    });

    if (result.isConfirmed) {
      try {
        updateFaq(updatedData)
          .unwrap()
          .then(() => {
            toastMessage("success", "FAQ updated successfully");
            window.location.href = "/faqs";
          });
      } catch (error) {
        Swal.fire("Error occurred while updating", "", "error");
      }
    } else if (result.dismiss) {
      Swal.fire("Cancelled", "", "error");
    }
  };
  const uploadFile = async (e: any) => {
    // const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", e);
    // console.log(e);
    try {
      const response = await axios.post(
        `${config.API_URL}/faq/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setFile(response.data?.file_url);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(data);
  return faqLoading ? (
    <FAQFormSkeleton {...{ fieldNum: 1 }} />
  ) : (
    <>
      <PageBreadcrumb
        title="Update FAQ"
        name="Update FAQ"
        breadCrumbItems={["IKY", "FAQ", "Update FAQ"]}
      />
      <div className=" p-5 mx-auto card mt-5">
        <div className="p-6">
          {!faqLoading && (
            <form onSubmit={handleUpdate}>
              <div className="grid grid-cols-1 gap-5">
                <div>
                  <label
                    htmlFor="faq-question"
                    className="text-gray-800 text-sm font-medium inline-block mb-2"
                  >
                    Question
                  </label>
                  <FormInput
                    type="text"
                    name="question"
                    className="form-input"
                    id="faq-question"
                    value={updatedQuestion}
                    onChange={(e) => setUpdatedQuestion(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="faq-answer"
                    className="text-gray-800 text-sm font-medium inline-block mb-2"
                  >
                    Answer
                  </label>
                  <FormInput
                    type="textarea"
                    name="answer"
                    className="form-textarea h-48"
                    id="faq-answer"
                    value={updatedAnswer}
                    onChange={(e) => {
                      setUpdatedAnswer(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full my-4">
                  <Dropzone
                    onDrop={(acceptedFiles) => uploadFile(acceptedFiles[0])}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="flex justify-center w-full h-40 p-0 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                        <div
                          {...getRootProps()}
                          className="w-full h-full relative"
                        >
                          <input
                            type="file"
                            name="file"
                            {...getInputProps()}
                            className="w-full h-full"
                            // value={""}
                          />
                          <div className=" w-full h-full flex items-center justify-center">
                            <p className="text-center">
                              <span className="text-primary font-semibold">
                                Drag 'n' drop some files here, or click to
                                select files
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {/* display file with url */}
                  {file && (
                    <div className="flex justify-center p-2 mt-2 transition bg-white appearance-none cursor-pointer focus:outline-none">
                      <div className="w-full h-full relative">
                        <p className="text-lg">
                          Uploaded Document:{" "}
                          <Link
                            to={`${config.BACKEND_URL}/storage/${file}`}
                            target="_blank"
                          >
                            <span className="text-primary">
                              {`${config.BACKEND_URL}/storage/${file}`}
                            </span>
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-start my-2">
                  <button
                    className="btn bg-primary/25 text-primary hover:bg-primary dark:bg-success dark:text-white hover:text-white"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateFAQ;
