import { apiSlice } from "../apiSlice";

export const packageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPackages: builder.query({
      query: () => `/admin/get-all-packages`,
    }),
    getPackage: builder.query({
      query: (packageId) => `/admin/get-single-package/${packageId}`,
    }),
    createPackage: builder.mutation({
      query: (body) => ({
        url: `/admin/add-package`,
        method: "POST",
        body,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // pessimistic update
        try {
          const { data: packageData } = await queryFulfilled;
          dispatch(
            packageApiSlice.util.updateQueryData(
              "getAllPackages",
              undefined,
              (draft) => {
                draft.push(packageData);
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updatePackage: builder.mutation({
      query: (body) => ({
        url: `/admin/update-package/${body.packageId}`,
        method: "PUT",
        body,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: packageData } = await queryFulfilled;
          dispatch(
            packageApiSlice.util.updateQueryData(
              "getAllPackages",
              undefined,
              (draft) => {
                return draft.map((p: any) => {
                  if (p.id === packageData.id) {
                    return packageData;
                  }
                  return p;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    deletePackage: builder.mutation({
      query: (packageId) => ({
        url: `/admin/delete-package/${packageId}`,
        method: "DELETE",
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            packageApiSlice.util.updateQueryData(
              "getAllPackages",
              undefined,
              (draft) => {
                return draft.filter((p: any) => p.id !== arg);
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    activatePackage: builder.mutation({
      query: (packageId) => ({
        url: `/admin/activate-package/${packageId}`,
        method: "PATCH",
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: packageData } = await queryFulfilled;
          dispatch(
            packageApiSlice.util.updateQueryData(
              "getAllPackages",
              undefined,
              (draft) => {
                return draft.map((p: any) => {
                  if (p.id === packageData.id) {
                    return packageData;
                  }
                  return p;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGetAllPackagesQuery,
  useGetPackageQuery,
  useCreatePackageMutation,
  useUpdatePackageMutation,
  useDeletePackageMutation,
  useActivatePackageMutation,
} = packageApiSlice;
