import { createSlice } from "@reduxjs/toolkit";

// state typ
export interface AuthStateType {
  user: {} | any;
  loading: boolean;
  error: string | undefined;
  token: string;
  userLoggedIn: boolean;
}

// initial state
const initialState: AuthStateType = {
  user: {},
  loading: false,
  error: "",
  token: "",
  userLoggedIn: false,
};

// create slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.userLoggedIn = true;
      state.token = localStorage.getItem("token") || "";
    },
    // logout user
    removeUser: (state) => {
      state.user = {};
      state.userLoggedIn = false;
      state.token = "";
      localStorage.removeItem("token");
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      localStorage.removeItem("matching_subscription");
      
    },
  },
});

// export
export const { setUser, removeUser } = authSlice.actions;
export default authSlice;
