import { apiSlice } from "../apiSlice";

export const subscriptionApislice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllcustomersubscription: builder.query({
      query: () => `/admin/get-all-customers-subscriptions`,
    }),
    getSingleCustomerSubscription: builder.query({
      query: () => `/get-single-customer-subscriptions`,
    }),
    createSubscription: builder.mutation({
      query: (body) => ({
        url: `/add-subscribe`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllcustomersubscriptionQuery,
  useGetSingleCustomerSubscriptionQuery,
  useCreateSubscriptionMutation,
} = subscriptionApislice;
