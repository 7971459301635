import { apiSlice } from "../apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomers: builder.query({
      query: () => `/admin/get-all-customers`,
    }),
    // checkAndFetchAdviceLocalReport: builder.query({
    //   query: (clientId) => `/admin/check-and-fetch-advice-local-report/${clientId}`,
    // }),
    getAllCustomersWithParams: builder.query({
      query: (queries) =>
        `/admin/get-custom-all-customers?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),

    getAllCustomersInvoices: builder.query({
      query: (queries) =>
        `/get-all-customers-invoices?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),
    getSingleCustomerInvoice: builder.query({
      query: (body) =>
        `/get-single-customer-invoice/${body.user_id}/invoices/${body.id}`,
    }),

    getSingleCustomerReport: builder.query({
      query: (clientid) => `admin/get-custom-single-customer/${clientid}`,
    }),
    
    getSingleCustomer: builder.query({
      query: (user_id) => `admin/get-single-customer/${user_id}`,
    }),

    getAdviceSingleCustomer: builder.query({
      query: (client_id) => `admin/get-advice-single-client/${client_id}`,
    }),
    createCustomer: builder.mutation({
      query: (body) => ({
        url: `/admin/add-customer`,
        method: "POST",
        body,
      }),
    }),
    updateCustomer: builder.mutation({
      query: (body) => ({
        url: `/admin/update-customer/${body.user_id}`,
        method: "PATCH",
        body,
      }),
    }),
    // add or change customer sale rep
    updateCustomerSaleRep: builder.mutation({
      query: (body) => ({
        url: `/admin/add-change-customer-salerep/${body.customer_id}`,
        method: "PATCH",
        body,
      }),
    }),

    updatePkgStatus: builder.mutation({
      query: (body) => ({
        url: `/admin/update-customer-subscription-status/${body.subscriptionId}/${body.customerId}`,
        method: "PATCH",
        body,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: scr } = await queryFulfilled;
          dispatch(
            customerApiSlice.util.updateQueryData(
              "getSingleCustomerReport",
              undefined,
              (draft) => {
                return draft.map((report: any) => {
                  if (report.id === scr.id) {
                    return scr;
                  }
                  return report;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),

    updateCustomerStatus: builder.mutation({
      query: (body) => ({
        url: `/admin/update-customer-status/${body.user_id}`,
        method: "PATCH",
        body,
      }),

      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: scr } = await queryFulfilled;
          dispatch(
            customerApiSlice.util.updateQueryData(
              "getSingleCustomerReport",
              undefined,
              (draft) => {
                return draft.map((report: any) => {
                  if (report.id === scr.id) {
                    return scr;
                  }
                  return report;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateAdviceSingleCustomer: builder.mutation({
      query: (body) => ({
        url: `admin/update-advice-single-client/${body.client_id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: scr } = await queryFulfilled;
          dispatch(
            customerApiSlice.util.updateQueryData(
              "getSingleCustomerReport",
              undefined,
              (draft) => {
                return draft.map((report: any) => {
                  if (report.id === scr.id) {
                    return scr;
                  }
                  return report;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),

    deleteCustomer: builder.mutation({
      query: (user_id) => ({
        url: `/admin/delete-customer/${user_id}`,
        method: "DELETE",
      }),
    }),

    getSingleCustomerSubscriptions: builder.query({
      query: (user_id) => `get-single-customers-subscriptions/${user_id}`,
    }),
    deleteAdviceLocalOrder: builder.mutation({
      query: (subscription_id) => ({
        url: `/admin/delete-advicelocal-order/${subscription_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllCustomersInvoicesQuery,
  useGetSingleCustomerInvoiceQuery,
  useGetAllCustomersQuery,
  useGetSingleCustomerReportQuery,
  useGetAllCustomersWithParamsQuery,
  useGetAdviceSingleCustomerQuery,
  useGetSingleCustomerQuery,
  useGetSingleCustomerSubscriptionsQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useUpdateCustomerSaleRepMutation,
  useUpdateCustomerStatusMutation,
  useUpdatePkgStatusMutation,
  useUpdateAdviceSingleCustomerMutation,
  useDeleteCustomerMutation,
  useDeleteAdviceLocalOrderMutation,
} = customerApiSlice;
