import { apiSlice } from "../apiSlice";

export const roleApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllRoles: builder.query({
            query: () => `/admin/get-all-roles`,
        }),
        getSingleRole: builder.query({
            query: (id) => `/admin/get-single-role/${id}`,
        }),
        createRole: builder.mutation({
            query: (body) => ({
                url: `/admin/add-role`,
                method: "POST",
                body,
            }),
        }),
        updateRole: builder.mutation({
            query: (body) => ({
                url: `/admin/update-role/${body.id} `,
                method: "PUT",
                body,
            }),
        }),
        deleteRole: builder.mutation({
            query: (id) => ({
                url: `admin/delete-role/${id}}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useGetAllRolesQuery,
    useGetSingleRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
} = roleApiSlice;
