const config = {
  APP_ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT || 'development',
  API_URL: import.meta.env.VITE_API_URL || 'http://localhost:8000/api',
  BACKEND_URL: import.meta.env.VITE_BACKEND_URL || 'http://localhost:8000',
  FRONTEND_URL: import.meta.env.VITE_FRONTEND_URL || 'https://localhost:5173',  // Replace with your ngrok frontend URL

  // Paypal
  PAYPAL_CLIENT_ID: import.meta.env.VITE_PAYPAL_CLIENT_ID,
  PAYPAL_CLIENT_SECRET: import.meta.env.VITE_PAYPAL_CLIENT_SECRET,

  // Advice local
  ADVICE_API_BASE_URL: import.meta.env.VITE_ADVICE_API_BASE_URL,
  ADVICE_API_TOKEN: import.meta.env.VITE_ADVICE_API_TOKEN,

  // Stripe
  STRIPE_SECRET: import.meta.env.VITE_STRIPE_SECRET,
  STRIPE_PUBLIC_KEY : import.meta.env.VITE_STRIPE_PUBLIC_KEY,
};

export default config;
