import React from "react";
interface Props {
  fieldNum: number;
}
const FAQFormSkeleton: React.FC<Props> = ({ fieldNum }) => {
  const fieldNumber = Array(fieldNum).fill(null);
  return (
    <div className="w-1/2 mx-auto">
      <div className="card my-6 mx-auto">
        <div className="p-5">
          <div className="animate-pulse">
            <div className="grid grid-cols-1 gap-4">
              {fieldNumber.map((_, index) => (
                <div className="" key={index}>
                  <h3 className="mt-2 mb-4 w-2/5 h-5 bg-gray-200 rounded-md dark:bg-gray-700"></h3>
                  <hr />
                  <br />
                  <h3 className="my-2 w-2/5 h-5 bg-gray-200 rounded-md dark:bg-gray-700"></h3>
                  <div className="w-full h-8 border rounded-md">
                    <h3 className="m-2 h-4 bg-gray-100 rounded-md dark:bg-gray-700"></h3>
                  </div>
                  <br />
                  <h3 className="my-2 w-2/5 h-5 bg-gray-200 rounded-md dark:bg-gray-700"></h3>
                  <div className="w-full h-56 border rounded-md">
                    <h3 className="m-2 h-4 bg-gray-100 rounded-md dark:bg-gray-700"></h3>
                  </div>
                </div>
              ))}
            </div>

            <h3 className="h-10 w-[100px] my-4 bg-gray-200 rounded-md dark:bg-gray-700"></h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQFormSkeleton;
