import { apiSlice } from "../apiSlice";

export const registrationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        checkSaleRepUserName: builder.query({
            query: (username) => `/check-sales-reps/${username}`,
        }),
        registerCustomer: builder.mutation({
            query: (body) => ({
                url: `/register-customer`,
                method: "POST",
                body,
            }),
        }),
        registerSalesReps: builder.mutation({
            query: (body) => ({
                url: `/register-salesreps`,
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useCheckSaleRepUserNameQuery,
    useRegisterCustomerMutation,
    useRegisterSalesRepsMutation,
} = registrationApiSlice;
