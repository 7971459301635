import { apiSlice } from "../apiSlice";

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomersPayments: builder.query({
      query: () => `/admin/get-all-customers-payments`,
    }),
    getAllCustomerPaymentWithParams: builder.query({
      query: (queries) =>
        `/admin/get-all-customers-custom-payments?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),
    getAllSalesrepsPaymentWithParams: builder.query({
      query: (queries) =>
        `/admin/get-all-salesreps-custom-payments?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),

    getCustomReportsWithParams: builder.query({
      query: (queries) =>
        `/admin/get-custom-reports?page=${queries.page}&perPage=${queries.perPage}&startDate=${queries.startDate}&endDate=${queries.endDate}`,
    }),

    getSingleCustomerPayments: builder.query({
      query: () => `/get-single-customer-payments`,
    }),
    getSinglePayments: builder.query({
      query: (id) => `/get-single-payments/${id}`,
    }),
    getAllSaleRepsInvoices: builder.query({
      query: () => `/get-all-sales-reps-invoices`,
    }),
    getSingleSaleRepsInvoices: builder.query({
      query: (id) => `/get-single-sales-rep-invoice/${id}`,
    }),
    getSaleRepsCommissions: builder.query({
      query: () => `/get-all-sales-reps-commissions`,
    }),

    // New endpoints for managing payment gateways
    getPaymentGateways: builder.query({
      query: () => `/admin/get-payment-gateways`,  // Adjusted backend route
    }),

    getSalesRepGateways: builder.query({
      query: () => `/admin/get-salesRep-gateways`,
    }),

    updatePaymentGateways: builder.mutation({
      query: (gateways) => ({
        url: `/admin/update-payment-gateways`,  // Adjusted backend route
        method: "POST",
        body: gateways,
      }),
    }),

    updateSalesRepGateways: builder.mutation({
      query: (gateways) => ({
        url: `/admin/update-salesRep-gateways`,
        method: "POST",
        body: gateways,
      })
    }),

  }),
});

export const {
  useGetAllCustomersPaymentsQuery,
  useGetAllCustomerPaymentWithParamsQuery,
  useGetAllSalesrepsPaymentWithParamsQuery,
  useGetSingleCustomerPaymentsQuery,
  useGetSinglePaymentsQuery,
  useGetAllSaleRepsInvoicesQuery,
  useGetSingleSaleRepsInvoicesQuery,
  useGetSaleRepsCommissionsQuery,
  useGetCustomReportsWithParamsQuery,
  useGetPaymentGatewaysQuery,       // Newly added
  useGetSalesRepGatewaysQuery,
  useUpdateSalesRepGatewaysMutation,
  useUpdatePaymentGatewaysMutation, // Newly added
} = paymentApiSlice;
