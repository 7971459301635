import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { apiSlice } from "./rtk/features/apiSlice";
// saga

// reducers
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import Layout from "./layout/reducers";
import { adviceLocalApiSlice } from "./rtk/features/adviceLocal/adviceLocalApiSlice";
import { authApi } from "./rtk/features/auth/authApi";
import authSlice from "./rtk/features/auth/authSlice";
import { customerApiSlice } from "./rtk/features/customer/customerApiSlice";
import { reportApiSlice } from "./rtk/features/dashboard/reportApiSlice";
import { documentApiSlice } from "./rtk/features/document/documentApiSlice";
import { faqApiSlice } from "./rtk/features/faq/faqApiSlice";
import { packageApiSlice } from "./rtk/features/package/packageApiSlice";
import { paymentApiSlice } from "./rtk/features/payment/paymentApiSlice";
import { registrationApiSlice } from "./rtk/features/registration/registrationApiSlice";
import { roleApiSlice } from "./rtk/features/role/roleApiSlice";
import { rolePermissionApiSlice } from "./rtk/features/role/rolePermissionApiSlice";
import { saleRepApiSlice } from "./rtk/features/saleRep/saleRepApiSlice";
import { settingsApiSlice } from "./rtk/features/settings/settingsApiSlice";
import { subscriptionApislice } from "./rtk/features/subscription/subscriptionApislice";
import { userApiSlice } from "./rtk/features/user/userApiSlice";
// conbine reducers
const rootReducer = combineReducers({
  // key: value
  // rtk query
  [apiSlice.reducerPath]: apiSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  // [userApiSlice.reducerPath]: userApiSlice.reducer,
  // [customerApiSlice.reducerPath]: customerApiSlice.reducer,
  // [saleRepApiSlice.reducerPath]: saleRepApiSlice.reducer,
  // [packageApiSlice.reducerPath]: packageApiSlice.reducer,
  // [roleApiSlice.reducerPath]: roleApiSlice.reducer,
  // [rolePermissionApiSlice.reducerPath]: rolePermissionApiSlice.reducer,
  // [paymentApiSlice.reducerPath]: paymentApiSlice.reducer,
  // [subscriptionApislice.reducerPath]: subscriptionApislice.reducer,
  // [faqApiSlice.reducerPath]: faqApiSlice.reducer,
  // [reportApiSlice.reducerPath]: reportApiSlice.reducer,
  // [registrationApiSlice.reducerPath]: registrationApiSlice.reducer,
  // [documentApiSlice.reducerPath]: documentApiSlice.reducer,
  // [settingsApiSlice.reducerPath]: settingsApiSlice.reducer,
  [adviceLocalApiSlice.reducerPath]: adviceLocalApiSlice.reducer,

  // tollkit
  Auth: authSlice.reducer as any,
  Layout: Layout as any,
});

// create the saga middleware
// const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

// mount it on the store
export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(authApi.middleware)
      .concat(userApiSlice.middleware)
      .concat(customerApiSlice.middleware)
      .concat(saleRepApiSlice.middleware)
      .concat(packageApiSlice.middleware)
      .concat(roleApiSlice.middleware)
      .concat(rolePermissionApiSlice.middleware)
      .concat(paymentApiSlice.middleware)
      .concat(subscriptionApislice.middleware)
      .concat(faqApiSlice.middleware)
      .concat(documentApiSlice.middleware)
      .concat(registrationApiSlice.middleware)
      .concat(reportApiSlice.middleware)
      .concat(settingsApiSlice.middleware)
      .concat(adviceLocalApiSlice.middleware),
  // .concat(middleware)
});

// run the saga
// sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState> | any;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action
>;
