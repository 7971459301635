import React from "react";
import {
  Navigate,
  Route,
  RouteObject,
  RouteProps,
  Routes,
  useLocation,
} from "react-router-dom";
// redux
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from ".";
import config from "../config";

const AllRoutes = (props: RouteProps) => {
  const { Layout, user, userLoggedIn } = useSelector((state: RootState) => ({
    Layout: state.Layout,
    user: state.Auth.user,
    userLoggedIn: state.Auth.userLoggedIn,
  }));

  const loggedInUser = localStorage.getItem("loggedInUser");
  const location = useLocation();

  return (
    <React.Fragment>
      {/* <Routes>
        <Route>
          {(publicProtectedFlattenRoutes || []).map(
            (route: RouteObject, idx: number) => (
              <Route
                path={route.path}
                element={
                  <DefaultLayout {...props} layout={Layout}>
                    {route.element}
                  </DefaultLayout>
                }
                key={idx}
              />
            )
          )}
          ;
        </Route>

        <Route>
          {(authProtectedFlattenRoutes || []).map(
            (route: RouteObject, idx: number) => (
              <Route
                path={route.path}
                element={
                  loggedInUser === null &&
                  location.pathname !== "/auth/login" &&
                  location.pathname !== "/auth/register" &&
                  location.pathname !== "/auth/sales-reps-register" ? (
                    // <Navigate
                    //   to={{
                    //     pathname: "/auth/login",
                    //     // search: "next=" + route.path,
                    //     search: "?next=" + location.pathname,
                    //   }}
                    // />
                    (window.location.href =
                      config.APP_ENVIRONMENT === "production"
                        ? "https://www.ikydigital.com/ikylocal-platform-registration-login"
                        : "/auth/login")
                  ) : (
                    <VerticalLayout {...props}>{route.element}</VerticalLayout>
                  )
                }
                key={idx}
              />
            )
          )}
          ;
        </Route>
      </Routes> */}
      <Routes>
        {/* Public Routes - No Authentication Check */}
        {publicProtectedFlattenRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <DefaultLayout {...props} layout={Layout}>
                {route.element}
              </DefaultLayout>
            }
            key={idx}
          />
        ))}

        {/* Protected Routes - Redirect if Not Authenticated */}
        {authProtectedFlattenRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              loggedInUser ? (
                <VerticalLayout {...props}>{route.element}</VerticalLayout>
              ) : (
                <Navigate to="/auth/login" state={{ from: location }} replace />
              )
            }
            key={idx}
          />
        ))}
      </Routes>

    </React.Fragment>
  );
};

export default AllRoutes;
