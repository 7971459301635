import { apiSlice } from "../apiSlice";

export const faqApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllFaqs: builder.query({
      query: () => `/admin/get-all-faqs`,
    }),
    getSingleFaq: builder.query({
      query: (id) => `/admin/get-single-faq/${id}`,
    }),
    createFaq: builder.mutation({
      query: (body) => ({
        url: `/admin/add-faq`,
        method: "POST",
        body,
      }),
    }),

    updateFaq: builder.mutation({
      query: (body) => ({
        url: `/admin/update-faq/${body.id} `,
        method: "PUT",
        body,
      }),
    }),
    deleteFaq: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-faq/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllFaqsQuery,
  useGetSingleFaqQuery,
  useCreateFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} = faqApiSlice;
