import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import config from "../../../config";

// create api slice
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.API_URL}`,
    prepareHeaders: (headers) => {
      headers.set("X-Requested-With", "XMLHttpRequest");
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");

      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});
