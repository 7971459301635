import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import config from "../../../../config";

// create api slice
export const adviceLocalApiSlice = createApi({
  reducerPath: "adviceLocalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.ADVICE_API_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("X-Requested-With", "XMLHttpRequest");
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");

      headers.set("x-api-token", config.ADVICE_API_TOKEN);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createAdviceLocalClient: builder.mutation({
      query: (data) => ({
        url: `/legacyclients`,
        method: "POST",
        body: data,
      }),
    }),
    // update advice local client
    updateAdviceLocalClient: builder.mutation({
      query: ({ id, data }) => ({
        url: `/legacyclients/${id}`,
        method: "POST",
        body: data,
      }),
    }),
    getAdviceLocalClient: builder.query({
      query: (id) => ({
        url: `/legacyclients/${id}`,
        method: "GET",
      }),
    }),

    getAdviceLocalClientReport: builder.query({
      query: (id) => ({
        url: `/legacyclients/${id}/report`,
        method: "GET",
      }),
    }),
    getALClientReportPdf: builder.query({
      query: (id) => ({
        url: `/legacyclients/${id}/pdf`,
        method: "GET",
      }),
    }),
  }),
});

// export api endpoints
export const {
  useCreateAdviceLocalClientMutation,
  useGetAdviceLocalClientQuery,
  useGetAdviceLocalClientReportQuery,
  useGetALClientReportPdfQuery,
} = adviceLocalApiSlice;
