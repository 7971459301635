import { apiSlice } from "../apiSlice";

export const rolePermissionApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllRolesPermission: builder.query({
            query: () => `/admin/get-all-roles`,
        }),
        getSingleRolePermission: builder.query({
            query: (id) => `/admin/get-single-role/${id}`,
        }),

        updateAllRolePermission: builder.mutation({
            query: (body) => ({
                url: `admin/update-all-roles-wise-permissions`,
                method: "PUT",
                body,
            }),
        }),
        updateSingleRolePermission: builder.mutation({
            query: (body) => ({
                url: `/admin/update-single-role-wise-permissions/${body.roleName} `,
                method: "PUT",
                body,
            }),
        }),
    }),
});

export const {
    useGetAllRolesPermissionQuery,
    useGetSingleRolePermissionQuery,
    useUpdateAllRolePermissionMutation,
    useUpdateSingleRolePermissionMutation,
} = rolePermissionApiSlice;
