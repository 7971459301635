import { apiSlice } from "../apiSlice";

export const documentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDocuments: builder.query({
      query: () => `/get-all-documents`,
    }),
    uploadFile: builder.mutation({
      query: (body) => ({
        url: `/document/upload-file`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        body: body,
        formData: true,
      }),
    }),
    createDocument: builder.mutation({
      query: (body) => ({
        url: `/add-documents`,
        method: "POST",
        body,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // pessimistic update
        try {
          const { data: newDocument } = await queryFulfilled;
          dispatch(
            documentApiSlice.util.updateQueryData(
              "getAllDocuments",
              undefined,
              (draft) => {
                draft.push(newDocument);
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateDocument: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-documents/${id}`,
        method: "PUT",
        body: data,
      }),
      // pesimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // pessimistic update
        try {
          const { data: newDocument } = await queryFulfilled;
          dispatch(
            documentApiSlice.util.updateQueryData(
              "getAllDocuments",
              undefined,
              (draft) => {
                return draft.map((document: any) => {
                  if (document.id === newDocument.id) {
                    return newDocument;
                  }
                  return document;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    // update position
    updatePosition: builder.mutation({
      query: (body) => ({
        url: `documents/update-position`,
        method: "PUT",
        body,
      }),
    }),
    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `/delete-documents/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // pessimistic update
        try {
          await queryFulfilled;
          dispatch(
            documentApiSlice.util.updateQueryData(
              "getAllDocuments",
              undefined,
              (draft) => {
                return draft.filter((document: any) => document.id !== arg);
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateSalesRepDash: builder.mutation({
      query: (body) => ({
        url: `/documents/update-sales-rep-dash`,
        method: "PATCH",
        body,
      }),
      async onQueryStarted({ selectedId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            documentApiSlice.util.updateQueryData(
              "getAllDocuments",
              undefined,
              (draft) => {
                draft.forEach((document: { sales_rep_dash: number; id: any; }) => {
                  document.sales_rep_dash = document.id === selectedId ? 1 : 0;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGetAllDocumentsQuery,
  useUploadFileMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useUpdatePositionMutation,
  useDeleteDocumentMutation,
  useUpdateSalesRepDashMutation,
} = documentApiSlice;
