import { apiSlice } from "../apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => `/admin/get-all-users`,
    }),

    getAllCustomUsers: builder.query({
      query: () => `/admin/get-all-custom-users`,
    }),

    getSingleUser: builder.query({
      query: (user_id) => `/admin/get-single-user/${user_id}`,
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: `/admin/add-user`,
        method: "POST",
        body,
      }),

      // pessimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // pessimistic update
        try {
          const { data: user } = await queryFulfilled;
          dispatch(
            userApiSlice.util.updateQueryData(
              "getAllUsers",
              undefined,
              (draft) => {
                draft.push(user);
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),

    updateUser: builder.mutation({
      query: (body) => ({
        url: `/admin/update-user/${body.id} `,
        method: "PATCH",
        body,
      }),
      // pessimistic update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled;
          dispatch(
            userApiSlice.util.updateQueryData(
              "getAllUsers",
              undefined,
              (draft) => {
                return draft.map((u: any) => {
                  if (u.id === user.id) {
                    return user;
                  }
                  return u;
                });
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    deleteUser: builder.mutation({
      query: (user_id) => ({
        url: `/admin/delete-user/${user_id}}`,
        method: "DELETE",
      }),
      // pessimistic delete
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            userApiSlice.util.updateQueryData(
              "getAllUsers",
              undefined,
              (draft) => {
                return draft.filter((u: any) => u.id !== arg);
              }
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGetAllCustomUsersQuery,
  useGetAllUsersQuery,
  useGetSingleUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
