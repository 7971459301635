import Swal from "sweetalert2";

const toastMessage = (type: "success" | "error", message: string) => {
  return Swal.fire({
    icon: type,
    title: type === "success" ? "Success" : "Error",
    text: message,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};

export default toastMessage;
